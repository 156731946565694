import { Form, Input, type TableProps } from 'antd';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import type { Key } from 'react';
import { globalAntdPagination } from '../../apartment/util/globalAntdPagination';
import { getBaseStockField } from './getBaseStockField';

export function getStockTableSetting(
	columns: TableProps['columns'] = [],
	options?: {
		isEditUuid?: Key[];
		cols?: string[];
		havingCost?: boolean;
	},
) {
	const isEditUuid = options?.isEditUuid;
	const cols = options?.cols;
	const havingCost = options?.havingCost;

	const genRender = (
		name: string,
		component: React.ReactNode,
		options?: { havingUuid?: boolean; type?: string },
	) => {
		return (v: unknown, record: any) => {
			if (isEditUuid?.includes(record.uuid)) {
				const index = isEditUuid?.findIndex((a) => a === record.uuid);

				const initValue = options?.type === 'number' ? Number(v) : v;

				return (
					<>
						{options?.havingUuid && (
							<Form.Item
								name={[index, 'uuid']}
								initialValue={record.uuid}
								hidden
							>
								<Input />
							</Form.Item>
						)}

						<Form.Item name={[index, name]} initialValue={initValue}>
							{component}
						</Form.Item>
					</>
				);
			}

			if (options?.type === 'date' && !isNil(v)) {
				return dayjs(v).format('YYYY年MM月DD日 HH:mm');
			}

			return v;
		};
	};

	const mCols = getBaseStockField()
		.map((a) => {
			let overProps = {};

			if (a?.options?.type === 'number') {
				overProps = {
					sorter: (rowA, rowB) => {
						if (!isNil(rowA[a.title]) && !isNil(rowB[a.title])) {
							return rowA[a.title] - rowB[a.title];
						}

						if (!isNil(rowA[a.title])) {
							return 1;
						}

						if (!isNil(rowB[a.title])) {
							return -1;
						}

						return -1;
					},
				};
			}

			if (a?.options?.type === 'string') {
				overProps = {
					sorter: (rowA, rowB) => {
						if (!isNil(rowA[a.title]) && !isNil(rowB[a.title])) {
							return rowA[a.title].length - rowB[a.title].length;
						}

						if (!isNil(rowA[a.title])) {
							return 1;
						}

						if (!isNil(rowB[a.title])) {
							return -1;
						}

						return -1;
					},
				};
			}

			if (a?.options?.type === 'date') {
				overProps = {
					sorter: (rowA, rowB) => {
						if (!isNil(rowA[a.title]) && !isNil(rowB[a.title])) {
							return (
								dayjs(rowA[a.title]).valueOf() - dayjs(rowB[a.title]).valueOf()
							);
						}

						if (!isNil(rowA[a.title])) {
							return 1;
						}

						if (!isNil(rowB[a.title])) {
							return -1;
						}

						return -1;
					},
					width: 200,
				};
			}

			return {
				title: a.title,
				dataIndex: a.title,
				width: a?.options?.width,
				render: genRender(a.title, a.component, a.options),
				...overProps,
			};
		})
		.filter((a) => {
			if (a.title === 'uuid') {
				return cols?.includes('uuid');
			}

			if (['createdAt', 'updatedAt', 'source'].includes(a.title)) {
				return cols?.includes('source');
			}

			if (a.title === 'cost') {
				return havingCost;
			}

			return true;
		});

	return {
		scroll: { x: 1600 },
		rowKey: 'uuid',
		pagination: globalAntdPagination,
		columns: [...mCols, ...columns],
	} as TableProps;
}
