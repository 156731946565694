import { DatePicker, Form, Input, InputNumber, type TableProps } from 'antd';

export function getBaseStockField() {
	return [
		{
			title: 'specifications',
			component: <Input />,
			options: { havingUuid: true, insertRequired: true, width: 150 },
		},
		{
			title: 'brand',
			component: <Input />,
			options: { insertRequired: true, width: 100 },
		},
		{
			title: 'ack',
			component: <Input />,
			options: { insertRequired: true, width: 150 },
		},
		{
			title: 'br#',
			component: <Input />,
			options: { insertRequired: true, width: 100 },
		},
		{
			title: 'lengths',
			component: <Input />,
			options: { insertRequired: true, width: 100 },
		},
		{
			title: 'pcs',
			component: <Input />,
			options: { width: 100 },
		},
		{
			title: 'bf',
			component: <InputNumber />,
			options: { type: 'number', width: 80 },
		},
		{
			title: 'cubic',
			component: <InputNumber />,
			options: { type: 'number', insertRequired: true, width: 80 },
		},
		{
			title: 'cost',
			component: <InputNumber />,
			options: { type: 'number', width: 80 },
		},
		{
			title: 'remark',
			component: <Input />,
			options: { type: 'string' },
		},
		{
			title: 'uuid',
			component: <Input disabled={true} />,
			options: { width: 300 },
		},
		{
			title: 'updatedAt',
			component: <DatePicker disabled={true} />,
			options: { width: 150, type: 'date' },
		},
		{
			title: 'createdAt',
			component: <DatePicker disabled={true} />,
			options: { width: 150, type: 'date' },
		},
		{
			title: 'source',
			component: <Input disabled={true} />,
			options: { width: 100, type: 'string' },
		},
	];
}
